import React from "react";
import "./ModalSermon.scss";
import { useForm } from "react-hook-form";
import axios from "axios";
import toast from "react-hot-toast";
import { ModalsContextType } from "../../types/modalsInterface";

export default function ModalSermon({
  setModSermonOpen,
}: Pick<ModalsContextType, "setModSermonOpen">) {
  const { register, handleSubmit, reset } = useForm({
    defaultValues: {
      videoId: crypto.randomUUID(),
      title: "",
      ref: "",
      speaker: "",
      desc: "",
      date: "",
      videoLink: "",
      timestamp: "",
      category: "",
      image: null,
      audioFile: null,
    },
  });

  const submitHandler = (data: any) => {
    const formData = new FormData();

    if (
      (data.audioFile === null || data.audioFile.length === 0) &&
      data.videoLink === ""
    ) {
      toast.error("Please add either a Youtube link or an audio file.");
      return;
    }

    for (const key in data) {
      formData.append(key, data[key]);
    }
    if (data.audioFile && data.audioFile[0]) {
      formData.append("audioFile", data.audioFile[0]);
    }
    if (data.image && data.image[0]) {
      formData.append("image", data.image[0]);
    }
    if (data.timestamp !== "") {
      const [minutes, seconds] = data.timestamp.split(":").map(Number);
      data.timestamp = `${minutes * 60 + seconds}`;
    }

    async function uploadSermon() {
      return axios.post(
        `${process.env.REACT_APP_BASE_URL}/sermon/upload`,
        formData,
        {
          headers: {
            Authorization: sessionStorage.getItem("token"),
            "Content-Type": "multipart/form-data",
          },
        }
      );
    }

    toast
      .promise(
        uploadSermon(),
        {
          loading: "Uploading Sermon...",
          success: "Sermon Uploaded",
          error: "Failed to Upload Sermon.",
        },
        { duration: 4750, className: "toast" }
      )
      .then(() => {
        reset();
        window.location.reload();
      })
      .catch((error) => {
        console.error("Failed to send message:", error);
      });
  };

  const handleClickOutside = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    if (event.target === event.currentTarget) {
      setModSermonOpen(false);
    }
  };

  return (
    <div className="modalSermon" onClick={handleClickOutside}>
      <form
        className="modalSermon__form"
        onClick={(e) => e.stopPropagation()}
        onSubmit={handleSubmit(submitHandler)}
      >
        <h1 className="modalSermon__header">Upload New Sermon</h1>
        <section className="modalSermon__body">
          <div className="modalSermon__wrapper">
            <label className="modalSermon__label">
              <p>
                <span className="modalSermon__req">*</span>Title
              </p>
              <input
                {...register("title")}
                type="text"
                className="modalSermon__input"
                required
                id="sermonTitle"
              />
            </label>
            <label className="modalSermon__label">
              <p>
                <span className="modalSermon__req">*</span>Bible Reference
              </p>
              <input
                {...register("ref")}
                type="text"
                className="modalSermon__input"
                required
                id="sermonRef"
              />
            </label>
          </div>
          <div className="modalSermon__wrapper">
            <label className="modalSermon__label">
              <p>
                <span className="modalSermon__req">*</span>Speaker
              </p>
              <input
                {...register("speaker")}
                type="text"
                className="modalSermon__input"
                required
                id="sermonSpeaker"
              />
            </label>
            <label className="modalSermon__label modalSermon__label--date">
              <p>
                <span className="modalSermon__req">*</span>Date
              </p>
              <input
                {...register("date")}
                type="date"
                className="modalSermon__date"
                required
                id="sermonDate"
              />
            </label>
          </div>
          <label className="modalSermon__label modalSermon__label--textarea">
            <p>
              <span className="modalSermon__req">*</span>Description
            </p>
            <textarea
              {...register("desc")}
              className="modalSermon__textarea"
              required
              id="sermonDesc"
            ></textarea>
          </label>
          <label className="modalSermon__label modalSermon__label--link">
            Youtube Link
            <input
              {...register("videoLink")}
              type="url"
              className="modalSermon__input modalSermon__input--link"
              id="sermonVideo"
            />
          </label>
          <div className="modalSermon__wrapper">
            <label className="modalSermon__label modalSermon__label--time">
              Timestamp
              <input
                {...register("timestamp")}
                type="text"
                className="modalSermon__input modalSermon__input--time"
                id="sermonTime"
              />
            </label>
            <label className="modalSermon__label modalSermon__label--category">
              Category
              <input
                {...register("category")}
                type="text"
                className="modalSermon__input modalSermon__input--category"
                id="sermonCat"
              />
            </label>
          </div>
          <div className="modalSermon__wrapper modalSermon__wrapper--fileNotice">
            <section className="modalSermon__files">
              <label className="modalSermon__label">
                <p>Audio File</p>
                <input
                  {...register("audioFile")}
                  type="file"
                  className="modalSermon__input--file"
                  id="sermonAudio"
                />
              </label>
              <label className="modalSermon__label">
                Series Image
                <input
                  {...register("image")}
                  type="file"
                  className="modalSermon__input--file"
                  id="sermonImg"
                />
              </label>
            </section>
            <section className="modalSermon__notice">
              <p className="modalSermon__notice-text">
                <span className="modalSermon__req">*</span>
                Indicates a required field
              </p>
            </section>
          </div>
          <div className="modalSermon__button-wrapper">
            <input
              type="submit"
              value="Upload Sermon"
              className="button button--adminSubmit"
            />
          </div>
        </section>
      </form>
    </div>
  );
}
