import React, { useEffect } from "react";
import "./Carousel.scss";
import { useState } from "react";
import { carouselItem } from "../../types/carouselInterface";
import carouselHome from "../../assets/images/carouselHome.jpg";

interface CarouselProps {
  items: carouselItem[];
}

const Carousel: React.FC<CarouselProps> = ({ items }) => {
  const [slide, setSlide] = useState<number>(0);
  const [touchPosition, setTouchPosition] = useState<number | null>(null);

  useEffect(() => {
    items.push({
      cImg: carouselHome,
      cAlt: "Welcome to Grace!",
      hidden: false,
    });
  }, [items]);

  const nextSlide = () => {
    setSlide(slide === items.length - 1 ? 0 : slide + 1);
  };

  const prevSlide = () => {
    setSlide(slide === 0 ? items.length - 1 : slide - 1);
  };

  const handleTouchStart = (e: React.TouchEvent<HTMLDivElement>) => {
    const touchDown = e.touches[0].clientX;
    setTouchPosition(touchDown);
  };

  const handleTouchMove = (e: React.TouchEvent<HTMLDivElement>) => {
    const touchDown = touchPosition;

    if (touchDown === null) {
      return;
    }

    const currentTouch = e.touches[0].clientX;
    const diff = touchDown - currentTouch;

    if (diff > 5) {
      nextSlide();
    }

    if (diff < -5) {
      prevSlide();
    }

    setTouchPosition(null);
  };

  return (
    <div
      className="carousel"
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
    >
      <span
        onClick={prevSlide}
        className="carousel__arrows carousel__arrows--left"
      >
        <i className="fa-solid fa-circle-arrow-left fa-3x "></i>
      </span>
      {items.map((item, i) => {
        return (
          <img
            src={item.cImg}
            alt={item.cAlt}
            key={i}
            className={
              slide === i
                ? "carousel__slide"
                : "carousel__slide carousel__slide--hidden"
            }
          />
        );
      })}
      <span
        onClick={nextSlide}
        className="carousel__arrows carousel__arrows--right"
      >
        <i className="fa-solid fa-circle-arrow-right fa-3x"></i>
      </span>
      <span className="carousel__indicators">
        {items.map((_, i) => {
          return (
            <button
              key={i}
              className={
                slide === i
                  ? "carousel__indicator"
                  : "carousel__indicator carousel__indicator--inactive"
              }
              onClick={() => setSlide(i)}
            ></button>
          );
        })}
      </span>
    </div>
  );
};

export default Carousel;
