import React, { useEffect, useMemo, useState } from "react";
import "./AdminSermon.scss";
import { AgGridReact } from "ag-grid-react";
import { ColDef, CellValueChangedEvent, GridApi } from "ag-grid-community";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import axios from "axios";
import toast from "react-hot-toast";
import { videoSermon } from "../../types/sermonInterface";
import { useOutletContext } from "react-router-dom";
import { ModalsContextType } from "../../types/modalsInterface";

export default function AdminSermon() {
  const { setModSermonOpen } = useOutletContext<ModalsContextType>();
  const { setModImageOpen } = useOutletContext<ModalsContextType>();

  const [rowData, setRowData] = useState<videoSermon[]>([]);
  const [colDefs] = useState<ColDef[]>([
    { field: "title", checkboxSelection: true },
    { field: "ref" },
    { field: "speaker", width: 150 },
    { field: "desc", filter: false },
    {
      field: "date",
      valueFormatter: (p) => {
        const date = new Date(p.value + "T00:00:00");
        return date.toLocaleDateString("en-US", {
          year: "numeric",
          month: "long",
          day: "numeric",
        });
      },
      width: 150,
    },
    { field: "videoLink", filter: false },
    {
      field: "timestamp",
      filter: false,
      valueFormatter: (p) => {
        if (!p.value) {
          return "";
        }

        const minutes = Math.floor(p.value / 60);
        const seconds = p.value % 60;
        return `${minutes}:${seconds.toString().padStart(2, "0")}`;
      },
      width: 100,
    },
    { field: "category" },
    {
      field: "image",
      valueFormatter: (p) => {
        if (!p.value) return "";

        const filename = p.value.split("/").pop();
        return filename;
      },
    },
    {
      field: "audioFile",
      filter: false,
      valueFormatter: (p) => {
        if (!p.value) return "";

        const filename = p.value.split("/").pop();
        return filename;
      },
      editable: false,
    },
  ]);
  const [gridApi, setGridApi] = useState<GridApi | null>(null);

  useEffect(() => {
    async function getSermonData() {
      return axios.get(`${process.env.REACT_APP_BASE_URL}/sermon/list`);
    }

    toast
      .promise(
        getSermonData(),
        {
          loading: "Getting sermon list data...",
          success: "Sermon data is ready to edit.",
          error: "Failed to retrieve sermon list data.",
        },
        { duration: 2000, className: "toast" }
      )
      .then((data) => {
        setRowData(data.data);
      })
      .catch((error) => {
        console.error("Failed to retrieve sermon list data:", error);
      });
  }, []);

  const defaultColDef = useMemo<ColDef>(() => {
    return {
      filter: true,
      editable: true,
    };
  }, []);

  function saveData() {
    const token = sessionStorage.getItem("token");

    async function sendData() {
      console.log(rowData);

      return axios.put(
        `${process.env.REACT_APP_BASE_URL}/sermon/list/edit`,
        rowData,
        {
          headers: { Authorization: token },
        }
      );
    }

    toast
      .promise(
        sendData(),
        {
          loading: "Saving...",
          success: "Sermon data has been saved.",
          error: "Failed to save sermon list data.",
        },
        { duration: 4000, className: "toast" }
      )
      .catch((error) => {
        console.error("Failed to save sermon list data:", error.data);
      });
  }

  function sanitizeData(data: videoSermon[]): videoSermon[] {
    return data.map((sermon) => ({
      ...sermon,
      ref: sermon.ref || "",
      speaker: sermon.speaker || "",
      desc: sermon.desc || "",
      videoLink: sermon.videoLink || undefined,
      timestamp: sermon.timestamp || "",
    }));
  }

  function handleCellValueChanged(p: CellValueChangedEvent) {
    if (p.colDef.field === "timestamp" && p.data.timestamp !== null) {
      const [minutes, seconds] = p.newValue.split(":").map(Number);
      p.data.timestamp = `${minutes * 60 + seconds}`;
    }

    if (p.colDef.field === "image") {
      if (!p.newValue.startsWith("https://") && p.newValue) {
        p.newValue = `https://storage.googleapis.com/grace-church-data/sermon-images/${p.newValue}`;
      }
      p.data.image = p.newValue;
    }

    if (p.colDef.field === "audioFile") {
      if (!p.newValue.startsWith("https://") && p.newValue) {
        p.newValue = `https://grace-fwb-server-ddda19527fac.herokuapp.com/gcs/audio/${p.newValue}`;
      }

      p.data.audio = p.newValue;
    }

    const updatedRowData = sanitizeData(rowData);
    setRowData(updatedRowData);
  }

  const handleDeleteSelected = () => {
    if (!gridApi) return;

    const selectedRows = gridApi.getSelectedRows();
    if (!selectedRows.length) {
      toast.error("Please select items to delete");
      return;
    }

    const videoIdsToDelete = selectedRows.map((row) => row.videoId);

    const updatedRowData = rowData.filter(
      (row) => !videoIdsToDelete.includes(row.videoId)
    );

    setRowData(updatedRowData);
    gridApi.deselectAll();
  };

  return (
    <div className="adminSermon">
      <section className="adminSermon__topBar">
        <h1 className="adminSermon__title">Sermon list</h1>
        <div className="adminSermon__buttons">
          <button
            onClick={handleDeleteSelected}
            className="button button--adminDelete"
          >
            Delete selected
          </button>
          <button
            onClick={() => setModImageOpen((prev) => !prev)}
            className="button button--adminNewImg"
          >
            Add Image
          </button>
          <button
            onClick={() => setModSermonOpen((prev) => !prev)}
            className="button button--adminNewRow"
          >
            Add Sermon
          </button>
          <button onClick={saveData} className="button button--adminSave">
            Save data
          </button>
        </div>
      </section>
      <section className="ag-theme-quartz adminSermon__wrapper">
        <AgGridReact
          rowData={rowData}
          columnDefs={colDefs}
          defaultColDef={defaultColDef}
          pagination={true}
          paginationPageSize={20}
          paginationPageSizeSelector={false}
          onCellValueChanged={handleCellValueChanged}
          rowSelection="multiple"
          onGridReady={(params) => setGridApi(params.api)}
        />
      </section>
    </div>
  );
}
