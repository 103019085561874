import React, { useEffect, useMemo, useState } from "react";
import "./AdminSlide.scss";
import { AgGridReact } from "ag-grid-react";
import { ColDef, CellValueChangedEvent, GridApi } from "ag-grid-community";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import axios from "axios";
import toast from "react-hot-toast";
import { carouselItem } from "../../types/carouselInterface";
import { useOutletContext } from "react-router-dom";
import { ModalsContextType } from "../../types/modalsInterface";

export default function AdminSlide() {
  const { setModCarouselOpen } = useOutletContext<ModalsContextType>();

  const [rowData, setRowData] = useState<carouselItem[]>([]);
  const [colDefs] = useState<ColDef[]>([
    {
      field: "cImg",
      headerName: "Slide Image",
      checkboxSelection: true,
      width: 250,
    },
    {
      field: "cAlt",
      headerName: "Descriptive Text",
      editable: true,
      width: 250,
    },
    {
      field: "hidden",
      headerName: "Hidden",
      editable: true,
      cellRenderer: (params: any) => {
        return (
          <input
            type="checkbox"
            checked={params.value}
            onChange={(e) => {
              params.node.setDataValue("hidden", e.target.checked);
            }}
          />
        );
      },
      cellStyle: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      },
      width: 80,
      filter: false,
    },
  ]);
  const [gridApi, setGridApi] = useState<GridApi | null>(null);

  useEffect(() => {
    async function getSlideData() {
      return axios.get(`${process.env.REACT_APP_BASE_URL}/carousel/list`);
    }

    toast
      .promise(
        getSlideData(),
        {
          loading: "Getting slide list data...",
          success: "Slide data is ready to edit.",
          error: "Failed to retrieve slide list data.",
        },
        { duration: 2000, className: "toast" }
      )
      .then((data) => {
        setRowData(data.data);
      })
      .catch((error) => {
        console.error("Failed to retrieve slide list data:", error);
      });
  }, []);

  const defaultColDef = useMemo<ColDef>(() => {
    return {
      filter: true,
    };
  }, []);

  function saveData() {
    const token = sessionStorage.getItem("token");

    async function sendData() {
      return axios.put(
        `${process.env.REACT_APP_BASE_URL}/carousel/list/edit`,
        rowData,
        {
          headers: { Authorization: token },
        }
      );
    }

    toast
      .promise(
        sendData(),
        {
          loading: "Saving...",
          success: "Slide data has been saved.",
          error: "Failed to save slide list data.",
        },
        { duration: 2000, className: "toast" }
      )
      .catch((error) => {
        console.error("Failed to save slide list data:", error.data);
      });
  }

  function sanitizeData(data: carouselItem[]): carouselItem[] {
    return data.map((slide) => ({
      ...slide,
      cAlt: slide.cAlt || "",
    }));
  }

  function handleCellValueChanged(p: CellValueChangedEvent) {
    if (p.colDef.field === "cImg") {
      if (!p.newValue.startsWith("https://") && p.newValue) {
        p.newValue = `https://storage.googleapis.com/grace-church-data/${p.newValue}`;
      }
      p.data.image = p.newValue;
    }

    const updatedRowData = sanitizeData(rowData);
    setRowData(updatedRowData);
  }

  const handleDeleteSelected = () => {
    if (!gridApi) return;

    const selectedRows = gridApi.getSelectedRows();
    if (!selectedRows.length) {
      toast.error("Please select items to delete");
      return;
    }

    const slidesToDelete = selectedRows.map((row) => row.cImg);

    const updatedRowData = rowData.filter(
      (row) => !slidesToDelete.includes(row.cImg)
    );

    setRowData(updatedRowData);
    gridApi.deselectAll();
  };

  return (
    <div className="adminSlide">
      <section className="adminSlide__topBar">
        <h1 className="adminSlide__title">Slide list</h1>
        <div className="adminSlide__buttons">
          <button
            onClick={handleDeleteSelected}
            className="button button--adminDelete"
          >
            Delete selected
          </button>
          <button
            onClick={() => setModCarouselOpen((prev) => !prev)}
            className="button button--adminNewRow"
          >
            Add Image
          </button>
          <button onClick={saveData} className="button button--adminSave">
            Save data
          </button>
        </div>
      </section>
      <section className="ag-theme-quartz adminSlide__wrapper">
        <AgGridReact
          rowData={rowData}
          columnDefs={colDefs}
          defaultColDef={defaultColDef}
          pagination={true}
          paginationPageSize={20}
          paginationPageSizeSelector={false}
          onCellValueChanged={handleCellValueChanged}
          rowSelection="multiple"
          onGridReady={(params) => setGridApi(params.api)}
        />
      </section>
    </div>
  );
}
