import React from "react";
import "./ModalImage.scss";
import { ModalsContextType } from "../../types/modalsInterface";
import axios from "axios";
import toast from "react-hot-toast";
import { useForm } from "react-hook-form";

export default function ModalImage({
  setModImageOpen,
}: Pick<ModalsContextType, "setModImageOpen">) {
  const { register, handleSubmit, reset } = useForm({
    defaultValues: { image: null },
  });

  const handleClickOutside = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    if (event.target === event.currentTarget) {
      setModImageOpen(false);
    }
  };

  const submitHandler = (data: any) => {
    const formData = new FormData();
    for (const key in data) {
      formData.append(key, data[key]);
    }

    formData.append("image", data.image[0]);

    async function uploadCarousel() {
      return axios.post(
        `${process.env.REACT_APP_BASE_URL}/sermon/image`,
        formData,
        {
          headers: {
            Authorization: sessionStorage.getItem("token"),
            "Content-Type": "multipart/form-data",
          },
        }
      );
    }
    toast
      .promise(
        uploadCarousel(),
        {
          loading: "Uploading Image...",
          success: "Image Uploaded",
          error: "Failed to Upload Image.",
        },
        { duration: 4750, className: "toast" }
      )
      .then(() => {
        reset();
        window.location.reload();
      })
      .catch((error) => {
        console.error("Failed to upload image:", error);
      });
  };

  return (
    <div className="modalImage" onClick={handleClickOutside}>
      <form
        className="modalImage__form"
        onClick={(e) => e.stopPropagation()}
        onSubmit={handleSubmit(submitHandler)}
      >
        <h1 className="modalImage__header">Upload New Image</h1>
        <section className="modalImage__body">
          <div className="modalImage__wrapper modalImage__wrapper--fileNotice">
            <section className="modalImage__files">
              <label className="modalImage__label">
                <p>
                  <span className="modalImage__req">*</span>Sermon Image
                </p>
                <input
                  {...register("image")}
                  type="file"
                  className="modalImage__input--file"
                  required
                />
              </label>
            </section>
            <section className="modalImage__notice">
              <p className="modalImage__notice-text">
                <span className="modalImage__req">*</span>
                Indicates a required field
              </p>
            </section>
          </div>
          <div className="modalImage__button-wrapper">
            <input
              type="submit"
              value="Upload Image"
              className="button button--adminSubmit"
            />
          </div>
        </section>
      </form>
    </div>
  );
}
